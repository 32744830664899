/** @jsx jsx */
import { Container, jsx, Box } from 'theme-ui'
import Layout from '~/components/layout'
import BlogPost from '~/components/blog-post'
import HeadingV2 from '~/componentsV2/heading'
import { ArticleInterface, BlogTemplateType } from '~/@types/models'
import PaginationSection from '~/componentsV2/paginationSection'
import { BlogCard } from '~/styled/components/blog-card'
import { uniq } from 'lodash'
import TagsNav, { TagsNavItems, TagsNavType } from '~/componentsV2/tags-nav'
import { useEffect, useState } from 'react'

const ShopifyBlog = ({ pageContext: { data } }: BlogTemplateType) => {
  const { title, articles } = data
  const [currentTag, setCurrentTag] = useState<TagsNavType | null>(null)
  const [tags, setTags] = useState<TagsNavItems | []>([])

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const _tags: TagsNavItems = [
      {
        name: 'latest',
        label: 'Latest',
      },
      ...uniq(articles.map((article: ArticleInterface) => article.tags).flat()).map(tag => ({
        name: tag?.replace(' ', '-') || '',
        label: tag || '',
      })),
    ]

    if (_tags.length > 1) {
      setTags(_tags)

      if (searchParams.has('tag')) {
        const urlTag = searchParams.get('tag')
        const findTag = _tags.find(tag => tag.name === urlTag)
        setCurrentTag(findTag || null)
      } else if (_tags.length > 0) {
        setCurrentTag(_tags[0])
      }
    }
  }, [])

  useEffect(() => {
    if (currentTag) {
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.set('tag', currentTag.name)
      window.history.replaceState({}, '', `${window.location.pathname}?${searchParams}`)
    }
  }, [currentTag])

  const showTags = tags.length > 1 && currentTag !== null

  return (
    <Layout headerVariant="default" title={title}>
      <Container variant="container">
        <HeadingV2 sx={{ mb: 20, mt: 40 }}> {title} </HeadingV2>
        <hr />
        {showTags && <TagsNav tags={tags} current={currentTag} setCurrent={setCurrentTag} />}
      </Container>
      {/* {featuredArticles && isRootBlog && <FeaturedArticles articles={featuredArticles} />} */}
      {articles && (
        <Box
          as="section"
          sx={{
            mt: 40,
          }}
        >
          <PaginationSection<ArticleInterface>
            items={articles.filter((article: ArticleInterface) => {
              if (currentTag?.name === 'latest' || currentTag === null) {
                return true
              }
              return article?.tags?.includes(currentTag?.label)
            })}
            itemsPerPage={6}
            columns={[1, 2, 3]}
            Component={(article: ArticleInterface) => {
              const title = article.title
              const thumbnail = article.image?.url
              const slug = article.handle
              const articleTags = article.tags?.length ? article.tags : undefined

              return (
                <Box key={slug}>
                  <BlogCard>
                    <BlogPost
                      contentWidth="wide"
                      imageV2={thumbnail}
                      tags={articleTags}
                      title={title}
                      //  excerpt={edge.node.frontmatter.excerpt}
                      hideExcerpt={true}
                      readMore={slug}
                    />
                  </BlogCard>
                </Box>
              )
            }}
          />
        </Box>
      )}
    </Layout>
  ) as JSX.Element
}

export default ShopifyBlog
