import ReactPaginate from 'react-paginate'
import styled from 'styled-components'

export const CustomPagination = styled(ReactPaginate).attrs({
  activeClassName: 'active',
})`
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  padding: 0 5rem;

  li {
    position: relative;
    a {
      color: #2b2828;
      padding: 0.1rem 1rem;
      background-color: transparent;
      border: 1px solid #f0f0f0;
      cursor: pointer;
    }

    &.previous,
    &.next,
    &.break {
      a {
        color: #2b2828;
        background-color: transparent;
        border-color: transparent;
      }
    }
    &.previous {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  li.active a {
    color: #2b2828;
    background-color: #f0f0f0;
    border-color: #f0f0f0;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;

    svg {
      path {
        fill: grey;
      }
    }
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`
export type ColumnsType = number | number[]

interface PaginationWrapperProps {
  columns?: ColumnsType
}

const responsiveQueries = [
  `%STYLE%`,
  `@media screen and (min-width: 40em){%STYLE%}`,
  `@media screen and (min-width: 56em){%STYLE%}`,
]

const getColumns = (columns: ColumnsType) => {
  const defaultColumns = [1, 2, 3]
  if (Array.isArray(columns)) {
    return columns
      .map((column, index) =>
        responsiveQueries[index].replace(
          '%STYLE%',
          `grid-template-columns: repeat(${column}, 1fr);`
        )
      )
      .join('')
  } else if (typeof columns === 'number') {
    return responsiveQueries[0].replace(
      '%STYLE%',
      `grid-template-columns: repeat(${columns}, 1fr);`
    )
  } else {
    return defaultColumns
      .map((column, index) =>
        responsiveQueries[index].replace(
          '%STYLE%',
          `grid-template-columns: repeat(${column}, 1fr);`
        )
      )
      .join('')
  }
}

export const PaginationWrapper = styled.div<PaginationWrapperProps>`
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: grid;
  grid-gap: 24px;

  ${({ columns }: PaginationWrapperProps) => getColumns(columns || 3)}
`
