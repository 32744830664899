/** @jsx jsx **/
import { jsx, SxStyleProp } from 'theme-ui'
import { FC, SVGProps } from 'react'

type Props = SVGProps<SVGSVGElement> &
  SxStyleProp & {
    color?: string
  }

export const ArrowMenu: FC<Props> = ({ color = 'black', ...rest }) => {
  return (
    <svg {...rest} width="5" height="10" viewBox="0 0 5 10" fill="none">
      <path d="M5 5L0.5 0.669872L0.5 9.33013L5 5Z" fill={color} />
    </svg>
  )
}
