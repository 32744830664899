import React, { useState } from 'react'
import { Container } from 'theme-ui'
import { ColumnsType, CustomPagination, PaginationWrapper } from './styles'
import { uniqueId } from 'lodash'
import { ArrowMenu } from '~/components/icons/arrowMenu'

interface Props<T extends unknown> {
  columns?: ColumnsType
  itemsPerPage: number
  items: T[]
  Component: React.ElementType
}

export default function PaginationSection<T extends unknown>({
  columns,
  itemsPerPage,
  items,
  Component,
}: Props<T>) {
  const [itemOffset, setItemOffset] = useState(0)

  const endOffset = itemOffset + itemsPerPage

  function Items({ currentItems, Component }: { currentItems: T[]; Component: React.ElementType }) {
    return (
      <PaginationWrapper columns={columns}>
        {currentItems &&
          currentItems.map((item, index) => (
            <Component
              key={uniqueId(`pagination-col-${index}`)}
              {...(item as Record<string, unknown>)}
            />
          ))}
      </PaginationWrapper>
    )
  }

  const currentItems = items.slice(itemOffset, endOffset)

  const pageCount = Math.ceil(items.length / itemsPerPage)

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % items.length
    setItemOffset(newOffset)
  }

  return (
    <Container variant="container">
      <Items currentItems={currentItems} Component={Component} />
      <CustomPagination
        breakLabel="..."
        nextLabel={<ArrowMenu />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount > 1 ? pageCount : 0}
        previousLabel={<ArrowMenu />}
        renderOnZeroPageCount={null}
      />
    </Container>
  )
}
