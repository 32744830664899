import styled from 'styled-components'

export const BlogCard = styled.div`
  display: contents;

  span {
    font-weight: normal;
    font-family: Roboto;
    margin-bottom: 10px;
  }
  h3 {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 2.5;
    margin-bottom: 10px;
  }
  a {
    margin-bottom: 50px;
    font-size: 16px;
    color: #2b2828;
    line-height: 1.13;
    font-weight: normal;
    text-decoration: underline;
    &:after {
      background-color: initial;
      width: initial;
      height: initial;
    }
  }
  .blog-image {
    height: 100%;
    object-fit: cover;
  }
`
