/** @jsx jsx **/
import { Link } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import { getImage } from 'gatsby-plugin-image'
import { Box, Heading, Link as StyledLink, Text, ThemeUIStyleObject, jsx } from 'theme-ui'
import { BlogPostType } from '~/@types/models'
import ImageWrapper from '~/components/image-wrapper'
import ImageV2 from '~/componentsV2/image'
import { space } from '~/gatsby-plugin-theme-ui'

export interface Props extends Omit<BlogPostType, 'image'> {
  contentWidth?: string
  titleVariant?: string
  externalLink?: boolean
  hideExcerpt?: boolean
  imageV2?: string
  image?: FluidObject
}

const sxStyles: ThemeUIStyleObject = {
  variant: 'text.articleButton',
  color: 'text',
  textDecoration: 'none',
  mb: 'md2',
  transition: 'all 0.3s ease-in-out',
  display: 'inline-block',
  '&:after': {
    content: '""',
    position: 'relative',
    top: '-4px',
    ml: 'md',
    display: 'inline-flex',
    width: 40,
    height: '2px',
    bg: 'text',
  },
  '&:hover': {
    fontWeight: 'bold',
  },
}

const BlogPost = ({
  contentWidth = 'small',
  image,
  tags,
  title,
  hideExcerpt = false,
  readMore,
  hideImage = false,
  titleVariant = 'title6',
  externalLink = false,
  imageV2,
}: Props) => {
  const LinkComponent = externalLink ? StyledLink : Link

  const linkProps = externalLink
    ? { href: readMore, target: '_blank', sx: sxStyles, className: 'article-link-ext' }
    : { to: readMore, sx: sxStyles, className: 'article-link-int' }

  // const BlogImage = () => {
  //   const imageParsed = getImage({
  //     images: imageV2,
  //     formats: ['auto', 'webp', 'avif'],
  //     placeholder: 'blurred',
  //     width: 800,
  //     height: 800,
  //   })

  //   return (
  //     <ImageV2
  //       image={imageParsed}
  //       alt={title || 'Blog post'}
  //       sx={{
  //         width: '100%',
  //         height: '100%',
  //       }}
  //     />
  //   )
  // }
  return (
    <Box>
      {!hideImage && (
        <Link to={readMore}>
          <ImageWrapper ratio={4 / 3}>
            {imageV2 ? (
              <img src={imageV2} alt={title || 'Blog post'} className="blog-image" />
            ) : (
              <Img fluid={image} alt={title || 'Blog post'} />
            )}
          </ImageWrapper>
        </Link>
      )}
      <Box
        sx={{
          m: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          mt: [space.l1],
          maxWidth: ['100%', null, null, contentWidth === 'small' ? '80%' : '100%'],
        }}
      >
        <div>
          {tags && (
            <Text
              as="span"
              variant="label"
              sx={{
                display: 'block',
                mb: 'md1',
              }}
            >
              {tags}
            </Text>
          )}
          <Heading
            as="h3"
            variant={titleVariant}
            sx={{
              mb: 'sm1',
            }}
          >
            {title}
          </Heading>
          {/* {!hideExcerpt && (
            <Text
              variant="small"
              sx={{
                color: 'muted',
                mb: 'md2',
              }}
            >
              excerpt
            </Text>
          )} */}
        </div>
        <LinkComponent {...linkProps}>Read More</LinkComponent>
      </Box>
    </Box>
  )
}

export default BlogPost
