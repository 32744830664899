import styled from 'styled-components'

export const TagButton = styled.button<{ isActive: boolean }>`
  border: none;
  background: transparent;
  color: #2b2828;
  text-decoration: ${props => (props.isActive ? 'underline' : 'none')};
  font-size: 16px;
  text-transform: uppercase;
  min-width: 80px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }
`

export const TagList = styled.ul`
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
  margin-top: 30px;
`

export const TagListItem = styled.li`
  &:not(:last-child) {
    margin-right: 60px;
  }
`
