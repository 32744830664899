import React from 'react'
import useMediaQuery from '~/hooks/use-media-query'
import { TagButton, TagList, TagListItem } from './styles'

export type TagsNavType = {
  label: string
  name: string
}

export type TagsNavItems = TagsNavType[]

type Props = {
  tags: TagsNavItems
  current: TagsNavType
  setCurrent: (current: TagsNavType) => void
}

export default function TagsNav({ tags, current, setCurrent }: Props) {
  const isDesktopOrLaptop = useMediaQuery({
    query: breakpoints => `(min-width: ${breakpoints[2]})`,
  })

  const isRootTag = !current

  if (!isDesktopOrLaptop) {
    return null
  }

  return (
    <TagList>
      {tags.map(({ label, name }, index) => (
        <TagListItem key={label}>
          <TagButton
            isActive={name === current.name || (isRootTag && index === 0)}
            onClick={() => {
              if (label) {
                setCurrent({ label, name })
              }
            }}
          >
            {label}
          </TagButton>
        </TagListItem>
      ))}
    </TagList>
  )
}
